.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.inputfile + label {
  background-color: white;
  display: inline-block;
  border: 1px solid black;
  padding: 10px 32px;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
  width: 100%;
}

.inputfile + label.notEmpty {
  background-color: #0000FF;
  color: white;
}
