/* DayPicker styles */

.DayPicker {
  display: inline-block;
  font-size: 10px;
}

.DayPicker-wrapper {
  position: relative;

  flex-direction: row;
  padding-bottom: 1em;

  -webkit-user-select: none;

  -moz-user-select: none;

  -ms-user-select: none;

  user-select: none;
}

.DayPicker-Months {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.DayPicker-Month {
  display: table;

  border-spacing: 0;
  border-collapse: collapse;

  -webkit-user-select: none;

  -moz-user-select: none;

  -ms-user-select: none;

  user-select: none;
}

.DayPicker-NavBar {
}

.DayPicker-NavButton {
  position: absolute;
  top: 9px;
  right: 8px;
  left: auto;

  display: inline-block;

  width: 1.25em;
  height: 1.25em;
  background-position: center;
  background-size: 50%;
  background-repeat: no-repeat;
  color: black;
  cursor: pointer;
}

.DayPicker-NavButton:hover {
  opacity: 0.4;
}

.DayPicker-NavButton--prev {
  margin-right: 1.5em;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 4px 8px 4px 0;
  border-color: transparent black transparent transparent;
}

.DayPicker-NavButton--next {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 4px 0 4px 8px;
  border-color: transparent transparent transparent black;
}

.DayPicker-NavButton--interactionDisabled {
  display: none;
}

.DayPicker-Caption {
  display: table-caption;
  margin-bottom: 0.5em;

  padding: 8px;
  text-align: center;
  border-bottom: 1px solid black;
  text-transform: uppercase;
  font-family: "Haas Grot Text Pro";
  font-weight: 500;
}

.DayPicker-Caption > div {
  font-weight: 500;
}

.DayPicker-Weekdays {
  display: table-header-group;
  margin-top: 1em;
}

.DayPicker-WeekdaysRow {
  display: table-row;
}

.DayPicker-Weekday {
  display: table-cell;
  padding: 4px 4px;
  min-width: 27px;
  color: black;
  text-align: center;
  font-family: "Haas Grot Text Pro";
  font-weight: 500;
  text-transform: uppercase;
}

.DayPicker-Weekday abbr[title] {
  border-bottom: none;
  text-decoration: none;
}

.DayPicker-Body {
  display: table-row-group;
}

.DayPicker-Week {
  display: table-row;
}

.DayPicker-Day {
  display: table-cell;
  padding: 4px 4px;
  border-radius: 0;
  /* border: 8px solid red; */
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  font-family: "Haas Grot Text Pro";
  font-weight: 500;
}

.DayPicker-WeekNumber {
  display: table-cell;
  padding: 0.5em;
  min-width: 1em;
  border-right: 1px solid #eaecec;
  color: #8b9898;
  vertical-align: middle;
  text-align: right;
  font-size: 0.75em;
  cursor: pointer;
}

.DayPicker--interactionDisabled .DayPicker-Day {
  cursor: default;
}

.DayPicker-Footer {
  padding-top: 0.5em;
}

.DayPicker-TodayButton {
  border: none;
  background-color: transparent;
  background-image: none;
  box-shadow: none;
  color: #4a90e2;
  font-size: 0.875em;
  cursor: pointer;
}

/* Default modifiers */

.DayPicker-Day--today {
  color: #0000ff;
  font-weight: 700;
}

.DayPicker-Day--outside {
  color: #8b9898;
  cursor: default;
}

.DayPicker-Day--disabled {
  color: #dce0e0;
  cursor: default;
  /* background-color: #eff1f1; */
}

/* Example modifiers */

.DayPicker-Day--sunday {
  background-color: #f7f8f8;
}

.DayPicker-Day--sunday:not(.DayPicker-Day--today) {
  color: #dce0e0;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  position: relative;

  background-color: #0000ff;
  font-weight: 700;
  color: white;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
  background-color: #0000ff;
  opacity: 0.3;
}

.DayPicker:not(.DayPicker--interactionDisabled)
  .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
  background-color: #0000ff;
  color: white;
}

/* DayPickerInput */

.DayPickerInput {
  display: inline-block;
}

.DayPickerInput > input {
  font-family: "Haas Grot Disp Pro";
  font-weight: 500;
  font-size: 18px;
  border: 0;
  border-bottom: 1px solid black;
  padding: 4px 0px;
  margin: 0;
  outline: none;
}

.DayPickerInput-OverlayWrapper {
  position: relative;
}

.DayPickerInput-Overlay {
  position: absolute;
  left: 0;
  z-index: 1;
  border: 1px solid black;
  background: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}
