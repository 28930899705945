/*
        This font software is the property of Commercial Type.

        You may not modify the font software, use it on another website, or install it on a computer.

        License information is available at http://commercialtype.com/eula
        For more information please visit Commercial Type at http://commercialtype.com or email us at info[at]commercialtype.com

        Copyright (C) 2020 Schwartzco Inc.
        License: 2003-YHLZRV     
*/

@font-face {
  font-family: "Haas Grot Text Pro";
  src: url("NeueHaasGroteskTextPro.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

.NeueHaasGroteskTextPro-75Bold-Web {
  font-family: "Haas Grot Text Pro";
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Haas Grot Text Pro";
  src: url("NeueHaasGroteskTextPro.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

.NeueHaasGroteskTextPro-55Roman-Web {
  font-family: "Haas Grot Text Pro";
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Haas Grot Disp Pro";
  src: url("NeueHaasGroteskDisplayPro.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

.NeueHaasGrotDispPro-55Roman-Web {
  font-family: "Haas Grot Disp Pro";
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Dm Serif Disp";
  src: url("DmSerifDisplayRegular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

.DmSerifDisp-Web {
  font-family: "Dm Serif Disp";
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}
