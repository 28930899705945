.swiper-button-next { 
    background-image: url(../../../icons/svg/ArrowRight2.svg);
    background-repeat: no-repeat;
    background-size: 50% auto;
    background-position: center;
    /* filter: brightness(0) invert(1); */

 }
 .swiper-button-next::after {
    display: none;
  }

  .swiper-button-prev { 
    background-image: url(../../../icons/svg/ArrowRight2.svg);
    background-repeat: no-repeat;
    background-size: 50% auto;
    background-position: center;
    transform: rotate(180deg);
 }
 
 .swiper-button-prev::after {
    display: none;
  }

.swiper-button-next:hover, .swiper-button-prev:hover {
   opacity: 0.8
 }

.swiper-pagination-bullet {
    color: #000;
    border-radius: 0;
  }
  
  .swiper-pagination-bullet-active {
    color: #fff;
    background:blue;
    border-radius: 0;
  }
  /* .swiper-pagination-bullet-active-next, .swiper-pagination-bullet-active-next-next, .swiper-pagination-bullet-active-prev, .swiper-pagination-bullet-active-prev-prev   {
    background: white;
    opacity: 1;
  } */

  /* .swiper-wrapper{
    padding-bottom: 25px;
} */

.swiper-container-horizontal>.swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction{
    bottom: 0px !important;
}

.noCustomBullets .swiper-wrapper{
  padding-bottom: 0px !important;
}

.noCustomBullets .swiper-container-horizontal>.swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction{
  bottom: 0px !important;
}